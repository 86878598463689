import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import mapstyle from "./mapstyle"

const defaultProps = {
  indiacenter: {
    lat: 23.0661349,
    lng: 72.529525,
  },
  usacenter: {
    lat: 37.337735,
    lng: -121.89637,
  },
  zoom: 17,
}

const Marker = ({ icon }) => (
  <div>
    <img src={icon} alt="WebMob Tech" title="WebMob Tech" loading="lazy" />
  </div>
)

function MapComponent() {
  const [center, setcenter] = useState({
    lat: 23.0661349,
    lng: 72.529525,
  })
  const [selectedMap, setselectedMap] = useState("india")

  return (
    <div className="h-screen w-full">
      <div className="lg:hidden md:hidden block relative">
        <div className="relative flex flex-wrap " aria-label="Global">
          <div
            className="block w-1/3 py-2 text-center"
            onClick={() => {
              setcenter({ lat: 23.0661349, lng: 72.529525 })
              setselectedMap("india")
            }}
            aria-hidden="true"
          >
            <div className="font-medium text-gray-500 hover:text-gray-900">
              India
            </div>
          </div>
          <div
            className="block w-1/3 py-2 text-center"
            onClick={() => {
              setcenter({ lat: 37.337735, lng: -121.89637 })
              setselectedMap("usa")
            }}
            aria-hidden="true"
          >
            <div className="font-medium text-gray-500 hover:text-gray-900">
              USA
            </div>
          </div>
          {/* add for dubai */}
          <div
            className="block w-1/3 py-2 text-center"
            onClick={() => {
              setcenter({ lat: 25.186638825017642, lng: 55.27986691556252 })
              setselectedMap("dubai")
            }}
            aria-hidden="true"
          >
            <div className="font-medium text-gray-500 hover:text-gray-900">
              Dubai
            </div>
          </div>
        </div>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCDfeCfbqNuivaaVTXCdznoL-eOINcPuF4" }}
        // defaultCenter={center}
        center={center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: [...mapstyle],
          zoomControl: false,
          streetViewControl: false,
          panControl: false,
          scrollwheel: false,
        }}
      >
        <Marker lat={center.lat} lng={center.lng} icon="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/marker.png" />
      </GoogleMapReact>
      <div className="hidden md:block lg:block">
        <div className="absolute w-full">
          <div
            className={`absolute ${selectedMap === "india"
              ? "bg-blue-500 text-white"
              : "bg-white text-shark-500"
              }  p-4 mb-20 bottom-32 cursor-pointer`}
            onClick={() => {
              setcenter({ lat: 23.0661349, lng: 72.529525 })
              setselectedMap("india")
            }}
            aria-hidden="true"
          >
            <div className="text-3xl font-semibold">
              <p>Head Quarter,</p>
              <p>202, Kalasagar,</p>
            </div>
            <div className="text-xl pt-2">
              <p>Ghatlodia, Ahmedabad,</p>
              <p>Gujarat, India 380061</p>
            </div>
          </div>
          <div
            className={`absolute ${selectedMap === "dubai"
              ? "bg-blue-500 text-white"
              : "bg-white text-shark-500"
              }  p-4 mb-12 bottom-0 cursor-pointer`}
            onClick={() => {
              setcenter({ lat: 25.186638825017642, lng: 55.27986691556252 })
              setselectedMap("dubai")
            }}
            aria-hidden="true"
          >
            <div className="text-3xl font-semibold">
              {/* <p>Dubai -</p> */}
              <p>201 , Building 4,</p>
              <p>Bay Square,</p>
              {/* <p>Dubai, UAE</p> */}
            </div>
            <div className="text-xl pt-2">
              <p>Dubai , United Arab Emirates</p>
              {/* <p>Gujarat, India 380061</p> */}
            </div>
          </div>
          <div
            className={`absolute ${selectedMap === "usa"
              ? "bg-blue-500 text-white"
              : "bg-white text-shark-500"
              } p-4 mb-12 right-0 bottom-0 cursor-pointer`}
            onClick={() => {
              setcenter({ lat: 37.337735, lng: -121.89637 })
              setselectedMap("usa")
            }}
            aria-hidden="true"
          >
            <div className="text-3xl font-semibold">
              <p>111</p>
              <p>N Market,</p>
              <p>Street #300</p>
            </div>
            <div className="text-xl pt-2">
              <p>San Jose, CA 95113,</p>
              <p>United States</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden lg:hidden block ${selectedMap === "india" ? "block" : "hidden"
          }`}
      >
        <div className="absolute w-full">
          <div
            className={`absolute p-4 mb-12 bottom-0 cursor-pointer bg-blue-500 text-white w-full`}
          >
            <div className="text-3xl font-semibold">
              <p>Head Quarter,</p>
              <p>202, Kalasagar,</p>
            </div>
            <div className="text-xl pt-2">
              <p>Ghatlodia, Ahmedabad,</p>
              <p>Gujarat, India 380061</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden lg:hidden block ${selectedMap === "usa" ? "block" : "hidden"
          }`}
      >
        <div className="absolute w-full">
          <div
            className={`absolute p-4 mb-12 bottom-0 cursor-pointer bg-blue-500 w-full`}
          >
            <div className="text-3xl font-semibold text-white w-full">
              <p>111</p>
              <p>N Market,</p>
              <p>Street #300</p>
            </div>
            <div className="text-xl text-white pt-2">
              <p>San Jose, CA 95113,</p>
              <p>United States</p>
            </div>
          </div>
        </div>
      </div>
      {/* add for dubai */}
      <div
        className={`md:hidden lg:hidden block ${selectedMap === "dubai" ? "block" : "hidden"
          }`}
      >
        <div className="absolute w-full">
          <div
            className={`absolute p-4 mb-12 bottom-0 cursor-pointer bg-blue-500 w-full`}
          >
            <div className="text-3xl font-semibold text-white w-full">
              <p>201 , Building 4,</p>
              <p>Bay Square,</p>
            </div>
            <div className="text-xl text-white pt-2">
              <p>Dubai , United Arab Emirates</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapComponent
